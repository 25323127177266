import React, { useState, useEffect } from 'react';
import './CustomerView.css';
import GoogleSignIn from './GoogleSignIn';
import { useTranslation } from '../hooks/useTranslation';

/**
 * CustomerView Component
 * 
 * Provides an interactive menu and ordering system for customers in the Panda Expresso application.
 * Allows users to browse menu items, add/remove items from cart, view order summary, and place orders.
 * 
 * @component
 * @returns {React.ReactElement} Rendered customer view with menu, order summary, and interaction controls
 */
function CustomerView() {
  /**
   * State to manage items in the customer's cart
   * @type {Array<{name: string, price: number, quantity: number, image_url: string}>}
   */
  const [cart, setCart] = useState([]);

  /**
   * State to store menu items fetched from the backend
   * @type {Array<{id: string, name: string, price: number, category: string, image_url: string}>}
   */
  const [menuItems, setMenuItems] = useState([]);

  /**
   * State to manage and display error messages
   * @type {string|null}
   */
  const [error, setError] = useState(null);

  /**
   * State to track the currently selected menu category for filtering
   * @type {string}
   */
  const [selectedCategory, setSelectedCategory] = useState('All');

  /**
   * State to control the visibility of order confirmation
   * @type {boolean}
   */
  const [showConfirmation, setShowConfirmation] = useState(false);

  /**
   * State to manage order summary visibility based on cart contents
   * @type {boolean}
   */
  const [orderVisible, setOrderVisible] = useState(false);

  /**
   * State to control authentication popup display
   * @type {boolean}
   */
  const [showPopup, setShowPopup] = useState(!localStorage.getItem('isSignedIn'));

  /**
   * State to store the current user's name
   * @type {string|null}
   */
  const [userName, setUserName] = useState(localStorage.getItem('userName') || null);

  /**
   * State to track whether the user is in guest mode
   * @type {boolean}
   */
  const [guestMode, setGuestMode] = useState(localStorage.getItem('isGuest') === 'true');

  /**
   * State to store popular items
   * @type {Array<{id: string, name: string, price: number, category: string, image_url: string}>}
   */
  const [popularItems, setPopularItems] = useState([]);

  const { translate } = useTranslation(process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY);

  /**
   * Fetch menu items from the backend API on component mount
   * Updates menuItems state or sets an error if the fetch fails
   */
  useEffect(() => {
    fetch('https://project-3-team3p.onrender.com/api/menu')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setMenuItems(data);
      })
      .catch(error => {
        setError('Failed to fetch menu items.');
      });

    // Fetch popular items
    fetch('https://project-3-team3p.onrender.com/api/popular-items')
      .then(response => response.json())
      .then(data => {
        setPopularItems(data);
      })
      .catch(error => {
        console.error('Error fetching popular items:', error);
      });
  }, []);

  /**
   * Update order summary visibility when cart contents change
   */
  useEffect(() => {
    setOrderVisible(cart.length > 0);
  }, [cart]);

  /**
   * Add an item to the cart or increment its quantity
   * 
   * @param {Object} item - The menu item to add to the cart
   */
  const addToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.name === item.name);
      
      if (existingItem) {
        return prevCart.map((cartItem) =>
          cartItem.name === item.name
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      }
      
      return [...prevCart, { ...item, quantity: 1 }];
    });
  };

  /**
   * Remove an item from the cart or decrement its quantity
   * 
   * @param {string} itemName - The name of the item to remove
   */
  const removeFromCart = (itemName) => {
    setCart((prevCart) => {
      const updatedCart = prevCart
        .map((cartItem) =>
          cartItem.name === itemName
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
        .filter((cartItem) => cartItem.quantity > 0); 
      return updatedCart;
    });
  };

  /**
   * Clear all items from the cart
   */
  const clearCart = () => {
    setCart([]);
  };

  /**
   * Calculate the total price of items in the cart
   * 
   * @type {number}
   */
  const total = cart.reduce((sum, item) => sum + parseFloat(item.price) * item.quantity, 0);

  /**
   * Handle the checkout process by submitting the order to the backend
   * Displays order confirmation and clears the cart upon successful submission
   */
  const handleCheckout = async () => {
    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: cart.map(item => ({
            ...item,
            quantity: item.quantity || 1
          })),
          total_amount: total
        }),
      });

      const result = await response.json();
      
      if (result.status === 'success') {
        setShowConfirmation(true);
        clearCart();
        
        setTimeout(() => {
          setShowConfirmation(false);
        }, 3000);
      }
    } catch (error) {
      setError('Failed to complete checkout.');
    }
  };

  /**
   * Filter menu items based on the selected category
   * 
   * @type {Array<Object>}
   */
  const filteredItems = selectedCategory === 'Popular' 
    ? popularItems 
    : selectedCategory === 'All'
    ? menuItems
    : menuItems.filter(item => item.category === selectedCategory);

  /**
   * Nested component to render individual menu item cards
   * Provides detailed item information and interactive features
   * 
   * @component
   * @param {Object} props - Component properties
   * @param {Object} props.item - The menu item to display
   * @param {function} props.onAddToCart - Callback function to add item to cart
   * @returns {React.ReactElement} Rendered menu item card
   */
  const MenuItemCard = ({ item, onAddToCart }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [currentItem, setCurrentItem] = useState(item);
    const [translatedContent, setTranslatedContent] = useState({
      name: item.name,
      description: item.description || 'No description available'
    });
    const [isTranslating, setIsTranslating] = useState(false);

    useEffect(() => {
      setCurrentItem(item);
    }, [item]);

    const formatAllergens = (allergens) => {
      if (!allergens || allergens.length === 0) return 'None';
      if (typeof allergens === 'string') {
        return allergens.replace(/[{}"]/g, '').split(',').join(', ');
      }
      if (Array.isArray(allergens)) {
        return allergens.join(', ');
      }
      return 'None';
    };

    useEffect(() => {
      const translateContent = async () => {
        const targetLang = document.documentElement.lang;
        if (showInfo && targetLang !== 'en' && !isTranslating) {
          try {
            setIsTranslating(true);
            // Store original content if not already stored
            if (!currentItem.originalName) {
              currentItem.originalName = currentItem.name;
              currentItem.originalDescription = currentItem.description || 'No description available';
            }

            // Only translate if content hasn't been translated to this language
            if (currentItem.translatedLang !== targetLang) {
              const [translatedName, translatedDesc] = await Promise.all([
                translate(currentItem.originalName, targetLang),
                translate(currentItem.originalDescription, targetLang)
              ]);
              
              // Also translate allergens if they exist
              let translatedAllergens = formatAllergens(currentItem.allergens);
              if (translatedAllergens !== 'None') {
                translatedAllergens = await translate(translatedAllergens, targetLang);
              }
              
              setTranslatedContent({
                name: translatedName,
                description: translatedDesc,
                allergens: translatedAllergens
              });
              currentItem.translatedLang = targetLang;
            }
          } catch (error) {
            console.error('Translation error:', error);
          } finally {
            setIsTranslating(false);
          }
        } else if (targetLang === 'en' && currentItem.originalName) {
          // Restore original content when switching back to English
          setTranslatedContent({
            name: currentItem.originalName,
            description: currentItem.originalDescription
          });
        }
      };
      translateContent();
    }, [showInfo, currentItem, translate]);

    return (
      <div className="menu-item">
        <button className="info-button" onClick={(e) => {
          e.stopPropagation();
          setShowInfo(true);
        }}>i</button>
        
        <img src={currentItem.image_url} alt={currentItem.name} />
        <h3 data-original-text={currentItem.name}>
          {document.documentElement.lang === 'en' ? currentItem.name : translatedContent.name}
        </h3>
        <p className="price">${parseFloat(currentItem.price).toFixed(2)}</p>
        <button onClick={() => onAddToCart(currentItem)}>Add to Order</button>

        {showInfo && (
          <>
            <div className="modal-backdrop" onClick={() => setShowInfo(false)} />
            <div className="info-modal">
              <button className="close-modal" onClick={() => setShowInfo(false)}>&times;</button>
              <h3 data-original-text={currentItem.name}>
                {document.documentElement.lang === 'en' ? currentItem.name : translatedContent.name}
              </h3>
              <p>
                <strong>Description:</strong> 
                <span data-original-text={currentItem.description || 'No description available'}>
                  {document.documentElement.lang === 'en' ? 
                    (currentItem.description || 'No description available') : 
                    translatedContent.description}
                </span>
              </p>
              <p><strong>Calories:</strong> {currentItem.calories || 'N/A'}</p>
              <p>
                <strong>Allergens:</strong> 
                <span data-original-text={formatAllergens(currentItem.allergens)}>
                  {document.documentElement.lang === 'en' ? 
                    formatAllergens(currentItem.allergens) : 
                    (translatedContent.allergens || formatAllergens(currentItem.allergens))}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  /**
   * Handle user sign-in process
   * 
   * @param {string} name - The name of the signed-in user
   */
  const handleSignIn = (name) => {
    setUserName(name);
    setShowPopup(false);
    localStorage.setItem('isSignedIn', 'true');
    localStorage.setItem('userName', name);
  };

  /**
   * Handle guest mode entry
   */
  const handleGuest = () => {
    setUserName('Guest');
    setGuestMode(true);
    setShowPopup(false);
    localStorage.setItem('isSignedIn', 'true');
    localStorage.setItem('isGuest', 'true');
  };

  return (
    <div className="customer-view">
      {showPopup && (
        <div className="popup">
          <h2>Welcome! Please Sign In or Continue as Guest</h2>
          <button onClick={handleGuest}>Continue as Guest</button>
          <GoogleSignIn onSignIn={handleSignIn} />
        </div>
      )}

      <h1>Menu</h1>
      
      <nav className="menu-nav">
        <button 
          className={selectedCategory === 'All' ? 'active' : ''} 
          onClick={() => setSelectedCategory('All')}
        >
          All Items
        </button>
        <button 
          className={selectedCategory === 'Popular' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Popular')}
        >
          Most Popular
        </button>
        <button 
          className={selectedCategory === 'Entree' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Entree')}
        >
          Entrees
        </button>
        <button 
          className={selectedCategory === 'Side' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Side')}
        >
          Sides
        </button>
        <button 
          className={selectedCategory === 'Appetizer' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Appetizer')}
        >
          Appetizers
        </button>
        <button 
          className={selectedCategory === 'Drink' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Drink')}
        >
          Drinks
        </button>
      </nav>

      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="content">
          <div className="menu-items">
            {filteredItems.map((item) => (
              <MenuItemCard key={item.id} item={item} onAddToCart={addToCart} />
            ))}
          </div>
          <div className={`order-summary ${orderVisible ? 'visible' : ''}`}>
            <h2>Your Order</h2>
            {cart.length === 0 ? (
              <p>Your order is empty</p>
            ) : (
              <>
                <ul>
                  {cart.map((item, index) => (
                    <li key={index} className="cart-item">
                      <span className="item-name">{item.name}</span>
                      <div className="quantity-controls">
                        <button onClick={() => addToCart(item)}>+</button>
                        <span className="quantity">{item.quantity}</span>
                        <button onClick={() => removeFromCart(item.name)}>-</button>
                        <button onClick={() => removeFromCart(item.name)}>Remove</button>
                      </div>
                      <span className="item-price">${parseFloat(item.price * item.quantity).toFixed(2)}</span>
                    </li>
                  ))}
                </ul>
                <p className="total">Total: ${total.toFixed(2)}</p>
                <button className="clear-cart" onClick={clearCart}>Clear Order</button>
                <button className="checkout" onClick={handleCheckout}>Checkout</button>
              </>
            )}
          </div>
        </div>
      )}

      {/* 
       * Order confirmation modal displayed after successful order placement
       * Shows a success message and allows user to continue shopping
       * Controlled by showConfirmation state variable
       */}
      {showConfirmation && (
        <div className="order-confirmation">
          <h2>Order Placed Successfully!</h2>
          <p>Thank you for your order.</p>
          <button onClick={() => setShowConfirmation(false)}>Continue Shopping</button>
        </div>
      )}
    </div>
  );
}

export default CustomerView;
