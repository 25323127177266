import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

/**
 * Navigation Bar Component
 * 
 * Provides the main navigation interface for the Panda Express POS application.
 * Features a responsive layout with the company logo and navigation links.
 * Uses React Router for client-side navigation between different views.
 * 
 * Navigation Options:
 * - Home (via logo)
 * - Customer Order View
 * - Cashier View
 * - Manager View
 * 
 * @component
 * @returns {React.ReactElement} Rendered navigation bar with logo and links
 */
function Navbar() {
  return (
    <nav className="navbar" role="navigation" aria-label="Main navigation">
      <div className="navbar-container">
        <Link 
          to="/" 
          className="navbar-logo"
          aria-label="Home"
        >
          <img 
            src="/assets/Panda_Express.svg" 
            alt="Panda Express Logo"
            width="150"
            height="50"
          />
        </Link>

        <div className="nav-links">
          <Link 
            to="/customer" 
            className="nav-link"
            aria-label="Go to customer order page"
          >
            Order Now
          </Link>
          <Link 
            to="/cashier" 
            className="nav-link"
            aria-label="Go to cashier view"
          >
            Cashier
          </Link>
          <Link 
            to="/manager" 
            className="nav-link"
            aria-label="Go to manager view"
          >
            Manager
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
