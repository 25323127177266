// Web Vitals Performance Reporting Module
// Provides a mechanism to measure and report key performance metrics
// Helps developers understand and improve web application performance

// Function to report web vitals metrics
// Takes a callback function to process performance entries
const reportWebVitals = onPerfEntry => {
  // Check if a valid performance entry callback is provided
  // Ensures the function is a callable function before proceeding
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Dynamically import web-vitals library
    // Allows for code-splitting and lazy loading of performance measurement tools
    import('web-vitals').then(({ 
      getCLS,   // Cumulative Layout Shift: Measures visual stability
      getFID,   // First Input Delay: Measures interactivity
      getFCP,   // First Contentful Paint: Measures loading performance
      getLCP,   // Largest Contentful Paint: Measures perceived load speed
      getTTFB   // Time to First Byte: Measures server response time
    }) => {
      // Invoke each metric measurement with the provided callback
      // These metrics help assess different aspects of web application performance
      getCLS(onPerfEntry);  // Tracks layout shifts during page load
      getFID(onPerfEntry);  // Measures how quickly page becomes interactive
      getFCP(onPerfEntry);  // Tracks when first content is painted
      getLCP(onPerfEntry);  // Measures loading performance of main content
      getTTFB(onPerfEntry); // Evaluates server response time
    });
  }
};

// Export the performance reporting function as the default export
// Can be used in index.js to track application performance
export default reportWebVitals;
