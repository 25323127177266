// OrderContext.js
// Provides a centralized state management solution for order-related operations
// Uses React Context API to share order state across multiple components

// Import necessary React hooks for creating and managing context
import React, { createContext, useState, useContext } from 'react';

// Create a context object for order management
// This allows sharing order state without prop drilling
const OrderContext = createContext();

// Custom hook to easily access order context in other components
// Simplifies the process of consuming the context
export const useOrder = () => useContext(OrderContext);

// OrderProvider component manages the entire order state and related operations
// Wraps child components and provides them with order management functions
export const OrderProvider = ({ children }) => {
  // State to store the current order
  // Initialized as an empty array
  const [order, setOrder] = useState([]);

  // Function to add an item to the order
  // Handles both new items and updating quantities of existing items
  const addItemToOrder = (item) => {
    setOrder((prevOrder) => {
      // Find if the item already exists in the order
      // Ensures single items are updated, not duplicated
      const existingItemIndex = prevOrder.findIndex(
        (orderItem) => orderItem.name === item.name && !orderItem.type
      );

      if (existingItemIndex !== -1) {
        // If item exists, update its quantity
        const updatedOrder = [...prevOrder];
        updatedOrder[existingItemIndex].quantity += item.quantity || 1;
        return updatedOrder;
      } else {
        // If item is new, add it to the order with a default quantity of 1
        return [...prevOrder, { ...item, quantity: item.quantity || 1 }];
      }
    });
  };

  // Function to update the quantity of an item in the order
  // Allows incrementing or decrementing item quantities
  const updateItemQuantity = (index, change) => {
    setOrder((prevOrder) => {
      const updatedOrder = [...prevOrder];
      const item = updatedOrder[index];
      const newQuantity = item.quantity + change;

      if (newQuantity > 0) {
        // Update item quantity if it's greater than zero
        updatedOrder[index] = { ...item, quantity: newQuantity };
      } else {
        // Remove the item if quantity reaches zero
        updatedOrder.splice(index, 1);
      }

      return updatedOrder;
    });
  };

  // Function to remove a specific item from the order by its index
  const removeItemFromOrder = (itemIndex) => {
    setOrder((prevOrder) => prevOrder.filter((_, index) => index !== itemIndex));
  };

  // Function to completely clear the entire order
  const clearOrder = () => {
    setOrder([]);
  };

  // Calculate the total price of all items in the order
  // Multiplies each item's price by its quantity and sums the results
  const totalOrderPrice = order.reduce((sum, item) => sum + item.price * item.quantity, 0);

  // Provide the order state and management functions to child components
  return (
    <OrderContext.Provider 
      value={{ 
        order,               // Current order array
        addItemToOrder,      // Function to add items
        updateItemQuantity,  // Function to update item quantities
        removeItemFromOrder, // Function to remove items
        clearOrder,          // Function to clear entire order
        totalOrderPrice      // Total price of all items
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
