import { useState } from 'react';

export function useTranslation(apiKey) {
  const [loading, setLoading] = useState(false);

  const translate = async (text, targetLang) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: text,
            target: targetLang,
            format: 'text'
          }),
        }
      );

      const data = await response.json();
      setLoading(false);
      
      if (data.error) {
        throw new Error(data.error.message);
      }
      
      return data.data.translations[0].translatedText;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return { translate, loading };
} 