import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  ReferenceLine
} from 'recharts';
import './ManagerView.css';

/**
 * ManagerView Component
 * 
 * Administrative dashboard for restaurant managers to monitor and analyze business metrics.
 * Features include:
 * - Real-time revenue tracking and visualization
 * - Sales performance analytics
 * - Inventory level monitoring
 * - Interactive data visualization using charts
 * - Historical data analysis
 * - Business metrics overview
 * 
 * Uses Recharts library for data visualization and provides
 * comprehensive insights for business decision-making.
 * 
 * @component
 * @returns {React.ReactElement} Rendered manager dashboard with analytics and controls
 */
function ManagerView() {
  const navigate = useNavigate(); // Initialize navigate for page navigation
  const [metrics, setMetrics] = useState(null);
  const [dailyRevenue, setDailyRevenue] = useState({});
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRevenue, setShowRevenue] = useState(true); // Default to show revenue
  const [loadingData, setLoadingData] = useState(false);
  const [targetRevenue, setTargetRevenue] = useState(10000);
  const [databaseInfo, setDatabaseInfo] = useState(null);
  const [loadingDbInfo, setLoadingDbInfo] = useState(false);
  const [timeRange, setTimeRange] = useState(30); // Default to 30 days
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [restockThreshold] = useState(20); // Add restock threshold state
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  // Date formatting function
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const fetchDatabaseInfo = async () => {
    setLoadingDbInfo(true);
    try {
      const response = await fetch(`https://project-3-team3p.onrender.com/api/database-info?days=${timeRange}`);
      const data = await response.json();
      setDatabaseInfo(data);
    } catch (error) {
      console.error('Error fetching database info:', error);
    } finally {
      setLoadingDbInfo(false);
    }
  };

  const fetchMetrics = async () => {
    try {
      const response = await fetch(`https://project-3-team3p.onrender.com/api/dashboard/metrics?days=${timeRange}`);
      const data = await response.json();
      setMetrics(data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const fetchDailyRevenue = async () => {
    try {
      const response = await fetch(`https://project-3-team3p.onrender.com/api/dashboard/daily-revenue?days=${timeRange}`);
      const data = await response.json();
      setDailyRevenue(data);
    } catch (error) {
      console.error('Error fetching daily revenue:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchMetrics(),
          fetchDailyRevenue(),
          fetchDatabaseInfo()
        ]);
      } catch (err) {
        setError('Failed to fetch dashboard data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  const fetchInventoryData = async () => {
    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/dashboard/inventory');
      const inventoryData = await response.json();
      setInventoryData(inventoryData);
    } catch (err) {
      setError('Failed to fetch inventory data');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchInventoryData(); // Fetch inventory data when component mounts
  }, []);

  const filterChartData = () => {
    return inventoryData.map(item => ({
      item_name: item.item_name,
      quantity: item.quantity
    }));
  };

  const loadSampleData = async () => {
    setIsLoading(true);
    setLoadingProgress(0);
    setLoadingMessage('Starting...');

    if (!targetRevenue || targetRevenue <= 0) {
      alert('Please enter a valid target revenue greater than 0');
      setIsLoading(false);
      return;
    }

    if (!window.confirm(`This will clear existing orders and generate new orders until reaching $${targetRevenue.toLocaleString()} in revenue. Are you sure?`)) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/dashboard/load-sample-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetRevenue }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const text = decoder.decode(value);
        const updates = text.split('\n\n').filter(Boolean);

        for (const update of updates) {
          try {
            const data = JSON.parse(update);
            if (data.progress >= 0) {
              setLoadingProgress(data.progress);
              setLoadingMessage(data.message);
            } else {
              throw new Error(data.message);
            }
          } catch (e) {
            console.error('Error parsing update:', e);
          }
        }
      }

      // Refresh data after loading
      await fetchDatabaseInfo();
      setLoadingMessage('Sample data loaded successfully!');
    } catch (error) {
      console.error('Error loading sample data:', error);
      setLoadingMessage('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) return <div>Loading dashboard...</div>;
  if (error) return <div className="error">{error}</div>;

  // Transform the data for recharts
  const chartData = Object.entries(dailyRevenue)
    .sort(([dateA, _], [dateB, __]) => new Date(dateA) - new Date(dateB))
    .map(([date, revenue]) => ({
      date,
      revenue: parseFloat(revenue)
    }));

  const formatCurrency = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const handleToggleCharts = () => {
    setShowRevenue(prev => !prev);
  };

  const goToRestockPage = () => {
    navigate('/restock'); // Navigate to restock page using navigate
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(parseInt(event.target.value));
  };

  return (
    <div className="manager-dashboard">
      <div className="manager-header">
        <h1>Manager Dashboard</h1>
        <div className="sample-data-controls">
          {!isLoading && (
            <button onClick={() => setShowAdvanced(!showAdvanced)}>
              {showAdvanced ? 'Show Less' : 'Show More'}
            </button>
          )}
          {isLoading && (
            <div className="loading-container">
              <div className="loading-bar">
                <div 
                  className="loading-progress" 
                  style={{ width: `${loadingProgress}%` }}
                ></div>
              </div>
              <p className="progress-percentage">{loadingProgress}% Complete</p>
            </div>
          )}
          {showAdvanced && (
            <div className="advanced-controls">
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setIsPasswordCorrect(e.target.value === 'emolga');
                }}
                placeholder="Enter password to access advanced features"
              />
              {isPasswordCorrect && (
                <>
                  <input
                    type="number"
                    value={targetRevenue}
                    onChange={(e) => setTargetRevenue(Number(e.target.value))}
                    placeholder="Target Revenue"
                    className="revenue-input"
                  />
                  <button 
                    className="load-sample-data-btn"
                    onClick={loadSampleData}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Generating Orders...' : 'Generate Sample Orders'}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="time-range-control">
        <label>Time Range: {timeRange} days</label>
        <input
          type="range"
          min="7"
          max="365"
          value={timeRange}
          onChange={handleTimeRangeChange}
          onMouseUp={fetchDatabaseInfo}
          onTouchEnd={fetchDatabaseInfo}
        />
      </div>

      <div className="metrics-grid">
        <div className="metric-card">
          <h3>Total Revenue ({timeRange} days)</h3>
          <p>${metrics?.totalRevenue ? metrics.totalRevenue.toFixed(2) : '0.00'}</p>
        </div>
        <div className="metric-card">
          <h3>Total Orders</h3>
          <p>{metrics?.orderCount || 0}</p>
        </div>
        <div className="metric-card">
          <h3>Average Order Value</h3>
          <p>${metrics?.orderCount ? (metrics.totalRevenue / metrics.orderCount).toFixed(2) : '0.00'}</p>
        </div>
      </div>


      <div className="toggle-container">
        <span className={!showRevenue ? 'active' : ''}>Inventory</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={showRevenue}
            onChange={() => setShowRevenue(!showRevenue)}
          />
          <span className="slider round"></span>
        </label>
        <span className={showRevenue ? 'active' : ''}>Revenue</span>
      </div>

      {showRevenue ? (
        <div className="revenue-chart">
          <h2>Daily Revenue (Last {timeRange} Days)</h2>
          <div style={{ height: '500px', width: '100%' }}>
            <ResponsiveContainer>
              <LineChart
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date"
                  tickFormatter={formatDate}
                  tick={{ fontSize: 12 }}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis 
                  tickFormatter={(value) => `$${value.toLocaleString()}`}
                />
                <Tooltip 
                  labelFormatter={formatDate}
                  formatter={(value) => [`$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`, 'Revenue']}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  stroke="#e23744"
                  activeDot={{ r: 8 }}
                  name="Revenue"
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="inventory-histogram">
          <h3>Total Inventory Histogram</h3>
          <div style={{ height: '500px', width: '100%' }}>
            <ResponsiveContainer>
              <BarChart
                data={filterChartData()}
                margin={{
                  top: 10,
                  right: 100,
                  left: 20,
                  bottom: 100
                }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis
                  dataKey="item_name"
                  angle={-45}
                  textAnchor="end"
                  height={100}
                  tick={{ fontSize: 14 }}
                />
                <YAxis />
                <Tooltip />
                <ReferenceLine y={restockThreshold} stroke="#ff0000" strokeDasharray="3 3" label={{ value: 'Restock Level', position: 'right' }} />
                <Bar dataKey="quantity" fill="#d02030" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      <div className="restock-button-container">
        <button className="restock-button" onClick={goToRestockPage}>Go to Restock Page</button>
      </div>

      {databaseInfo && (
        <div className="database-info">
          <div className="info-section">
            <h2>Menu Overview</h2>
            <p>Total Menu Items: {databaseInfo.menuItems}</p>
            <p>Date Range: {new Date(databaseInfo.dateRange?.[0]?.earliest).toLocaleDateString()} to {new Date(databaseInfo.dateRange?.[0]?.latest).toLocaleDateString()}</p>
          </div>

          <div className="info-section">
            <h2>Category Performance</h2>
            <div className="category-stats">
              {databaseInfo.categoryInsights?.revenue.map(cat => (
                <div key={cat.category} className="category-card">
                  <h3>{cat.category}</h3>
                  <p>Orders: {cat.order_count}</p>
                  <p>Revenue: ${parseFloat(cat.revenue).toLocaleString()}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="info-section">
            <h2>Popular Item Combinations</h2>
            <div className="combos-list">
              {databaseInfo.popularCombos?.map((combo, index) => (
                <div key={index} className="combo-item">
                  <p>{combo.item1} + {combo.item2}</p>
                  <p>Ordered together {combo.pair_count} times</p>
                </div>
              ))}
            </div>
          </div>

          <div className="info-section">
            <h2>Hourly Order Trends</h2>
            <div className="hourly-stats">
              {databaseInfo.hourlyTrends?.map(hour => (
                <div key={hour.hour} className="hour-stat">
                  <p>{hour.hour}:00</p>
                  <p>{hour.order_count} orders</p>
                  <p>${parseFloat(hour.avg_order_value).toFixed(2)} avg</p>
                </div>
              ))}
            </div>
          </div>

          <div className="info-section">
            <h2>Order Size Analysis</h2>
            <p>Average Items per Order: {parseFloat(databaseInfo.orderSizeMetrics?.avg_items).toFixed(1)}</p>
            <p>Largest Order Size: {databaseInfo.orderSizeMetrics?.max_items} items</p>
          </div>

          <div className="info-section">
            <h2>Top 5 Popular Items</h2>
            <div className="popular-items">
              {databaseInfo.popularItems?.map((item, index) => (
                <div key={item.name} className="popular-item">
                  <span className="rank">#{index + 1}</span>
                  <span className="name">{item.name}</span>
                  <span className="stats">
                    Sold: {item.total_quantity} | Orders: {item.order_count} | Revenue: ${parseFloat(item.revenue).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManagerView;