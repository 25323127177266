// Import necessary React hooks for state management and side effects
import React, { useState, useEffect } from 'react';

// Import component-specific styling
import './Weather.css';

/**
 * Weather Component
 * 
 * Fetches and displays current weather information based on user's geolocation.
 * Uses OpenWeatherMap API to provide real-time weather data.
 * Handles various states: loading, error, and successful data retrieval.
 * 
 * @component
 * @returns {React.ReactElement} Rendered weather widget displaying temperature and conditions
 */
function Weather() {
  /**
   * State to store weather data from OpenWeatherMap API
   * @type {[Object|null, Function]} Weather data object containing temperature and conditions
   */
  const [weather, setWeather] = useState(null);

  /**
   * State to track data loading status
   * @type {[boolean, Function]} Loading state indicator
   */
  const [loading, setLoading] = useState(true);

  /**
   * State to store error messages
   * @type {[string|null, Function]} Error message if weather data fetch fails
   */
  const [error, setError] = useState(null);

  useEffect(() => {
    /**
     * Fetches weather data using geolocation coordinates
     * 
     * @async
     * @param {GeolocationPosition} position - Browser's geolocation position object
     */
    const fetchWeather = async (position) => {
      try {
        const { latitude, longitude } = position.coords;

        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}&units=imperial`
        );
        
        if (!response.ok) {
          throw new Error('Weather data not available');
        }
        
        const data = await response.json();
        setWeather(data);
        setLoading(false);
      } catch (err) {
        console.error('Weather fetch error:', err);
        setError('Unable to fetch weather data');
        setLoading(false);
      }
    };

    /**
     * Handles geolocation errors
     * 
     * @param {GeolocationPositionError} err - Geolocation error object
     */
    const handleError = (err) => {
      console.error('Geolocation error:', err);
      setError('Unable to get location');
      setLoading(false);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(fetchWeather, handleError);
    } else {
      setError('Geolocation is not supported');
      setLoading(false);
    }
  }, []);

  if (loading) return (
    <div className="weather-widget" role="status" aria-live="polite">
      Loading weather...
    </div>
  );

  if (error) return (
    <div className="weather-widget error" role="alert">
      {error}
    </div>
  );

  if (!weather || !weather.main) return (
    <div className="weather-widget" role="alert">
      Weather unavailable
    </div>
  );

  return (
    <div className="weather-widget" role="status" aria-live="polite">
      <div className="weather-temp" aria-label="Temperature">
        {Math.round(weather.main.temp)}°F
      </div>
      <div className="weather-desc" aria-label="Weather conditions">
        {weather.weather && weather.weather[0] 
          ? weather.weather[0].description 
          : 'No description available'
        }
      </div>
    </div>
  );
}

export default Weather;