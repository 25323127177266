// Import necessary React hooks for state management and side effects
import React, { useState, useEffect } from 'react';

// Import component-specific styling
import './TextSizeControl.css';

/**
 * TextSizeControl Component
 * 
 * Provides an interactive slider to adjust the global font size of the application.
 * Enhances accessibility by allowing users to customize text readability.
 * Font size changes are persisted across sessions using localStorage.
 * 
 * @component
 * @returns {React.ReactElement} Rendered text size control slider
 */
function TextSizeControl() {
  /**
   * State to track and manage the current font size percentage
   * @type {[number, Function]} fontSize - Current font size percentage (default: 100%)
   */
  const [fontSize, setFontSize] = useState(100);

  /**
   * Event handler for font size slider changes
   * Updates font size dynamically and persists user preference
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event - Slider change event
   */
  const handleFontSizeChange = (event) => {
    const newSize = event.target.value;
    setFontSize(newSize);

    // Apply font size to the root HTML element
    document.documentElement.style.fontSize = `${newSize}%`;

    // Persist font size preference
    localStorage.setItem('fontSize', newSize);
  };

  /**
   * Effect hook to restore saved font size on component mount
   * Retrieves and applies the user's preferred font size from localStorage
   */
  useEffect(() => {
    const savedFontSize = localStorage.getItem('fontSize');

    if (savedFontSize) {
      setFontSize(savedFontSize);
      document.documentElement.style.fontSize = `${savedFontSize}%`;
    }
  }, []);

  return (
    <div className="text-size-control">
      <label 
        className="text-size-label"
        htmlFor="text-size-slider"
      >
        Text Size
      </label>
      <input
        id="text-size-slider"
        type="range"
        min="60"
        max="250"
        value={fontSize}
        onChange={handleFontSizeChange}
        className="text-size-slider"
        aria-label="Adjust text size"
        aria-valuemin={60}
        aria-valuemax={250}
        aria-valuenow={fontSize}
      />
      <span 
        className="text-size-value"
        aria-live="polite"
      >
        {fontSize}%
      </span>
    </div>
  );
}

export default TextSizeControl;
