import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

/**
 * GoogleSignIn Component
 * 
 * Provides user authentication functionality using Google OAuth.
 * Manages user login state, handles authentication flow, and provides logout mechanism.
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {function} props.onSignIn - Callback function triggered upon successful sign-in
 * @returns {React.ReactElement} Rendered Google Sign-In component
 */
function GoogleSignIn({ onSignIn }) {
  /**
   * State to manage the logged-in user's name
   * @type {string|null}
   */
  const [userName, setUserName] = useState(null);

  /**
   * Effect hook to restore user session on component mount
   * Checks local storage for previously saved user name
   */
  useEffect(() => {
    const savedName = localStorage.getItem('userName');
    
    if (savedName) {
      setUserName(savedName);
      if (onSignIn) onSignIn(savedName);
    }
  }, [onSignIn]);

  /**
   * Success handler for Google OAuth login
   * Processes successful authentication and extracts user information
   * 
   * @param {Object} credentialResponse - Google OAuth credential response
   * @param {string} credentialResponse.credential - JWT containing user details
   */
  const handleSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    
    setUserName(decoded.name);
    localStorage.setItem('userName', decoded.name);
    if (onSignIn) onSignIn(decoded.name);
  };

  /**
   * Error handler for authentication failures
   * Logs authentication errors to the console
   */
  const handleError = () => {
    console.error('Login Failed');
  };

  /**
   * Logout handler to clear user session
   * Removes user name from state and local storage
   */
  const handleLogout = () => {
    setUserName(null);
    localStorage.removeItem('userName');
  };

  return (
    <div className="google-signin">
      {!userName ? (
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          useOneTap
          theme="filled_black"
          shape="pill"
          size="large"
          text="signin_with"
          type="standard"
        />
      ) : (
        <div className="user-welcome">
          Welcome, {userName}!
          <button 
            onClick={handleLogout}
            className="logout-button"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

// Export GoogleSignIn component as default
// Makes the component available for use in other parts of the application
export default GoogleSignIn; 