// Import necessary React and routing dependencies
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Import application styling
import './App.css';

// Import custom components for different views and utilities
import Navbar from './components/Navbar';
import CustomerView from './components/CustomerView';
import CashierView from './components/CashierView';
import ManagerView from './components/ManagerView';
import Home from './components/Home';
import { OrderProvider } from './OrderContext';
import Weather from './components/Weather';
import LanguageSelector from './components/LanguageSelector';
import RestockPage from './components/Restockpage';
import TextSizeControl from './components/TextSizeControl';

function App() {
  // Handle language change by updating document language and storing in local storage
  const handleLanguageChange = (langCode) => {
    // Set the HTML document's language attribute
    document.documentElement.lang = langCode;
    // Persist selected language in browser's local storage
    localStorage.setItem('selectedLanguage', langCode);
  };

  // Effect hook to restore previously selected language on app load
  useEffect(() => {
    // Retrieve saved language from local storage
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      // Apply saved language to document if available
      document.documentElement.lang = savedLanguage;
    }
  }, []); // Empty dependency array means this runs only once on component mount

  return (
    // Wrap app with Google OAuth provider for authentication
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {/* Use React Router for client-side routing */}
      <Router>
        <div className="App">
          {/* Top navigation bar */}
          <Navbar />
          
          {/* Utility bar with text size control, weather, and language selector */}
          <div className="utility-bar">
            {/* Container for text size adjustment */}
            <div className="text-size-container">
              <TextSizeControl />
            </div>
            
            {/* Weather component to display current weather */}
            <Weather />
            
            {/* Language selector to change app language */}
            <LanguageSelector onLanguageChange={handleLanguageChange} />
          </div>
          
          {/* Main content area with routes */}
          <div className="main-content">
            {/* Order context provider to manage order-related state */}
            <OrderProvider>
              {/* Define application routes */}
              <Routes>
                {/* Home page route */}
                <Route path="/" element={<Home />} />
                {/* Customer view route */}
                <Route path="/customer" element={<CustomerView />} />
                {/* Manager view route */}
                <Route path="/manager" element={<ManagerView />} />
                {/* Cashier view route */}
                <Route path="/cashier" element={<CashierView />} />
                {/* Restock page route */}
                <Route path="/restock" element={<RestockPage />} />
              </Routes>
            </OrderProvider>
          </div>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;