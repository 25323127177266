// Import core React libraries for rendering and DOM manipulation
import React from 'react';
import ReactDOM from 'react-dom/client';

// Import global CSS styles
import './index.css';

// Import the main App component and performance reporting utility
import App from './App';
import reportWebVitals from './reportWebVitals';

// Create a React root using the DOM element with id 'root'
// This is typically defined in public/index.html
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the main App component
// React.StrictMode enables additional development-time checks and warnings
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Performance monitoring setup
// reportWebVitals allows tracking of key web vitals metrics
// Can be configured to log to console or send to analytics endpoint
// More info: https://bit.ly/CRA-vitals
reportWebVitals();
