import React, { useState, useEffect } from 'react';
import { useOrder } from '../OrderContext';
import './CashierView.css';

/**
 * CashierView Component
 * 
 * Provides a comprehensive interface for cashiers to process customer orders.
 * Features include:
 * - Menu item selection and categorization
 * - Combo meal building functionality
 * - Order management (add, update, remove items)
 * - Special requests handling
 * - Order total calculation
 * - Order confirmation and processing
 * 
 * @component
 * @returns {React.ReactElement} Rendered cashier interface for order processing
 */
function CashierView() {
  const { order, addItemToOrder, updateItemQuantity, removeItemFromOrder, clearOrder } = useOrder();
  const [menuItems, setMenuItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [comboType, setComboType] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);
  const [selectedEntrees, setSelectedEntrees] = useState([]);
  const [isComboComplete, setIsComboComplete] = useState(false);
  const [selectedSingleItem, setSelectedSingleItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [specialRequests, setSpecialRequests] = useState('');

  useEffect(() => {
    fetch('https://project-3-team3p.onrender.com/api/menu')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched menu items:', data);
        setMenuItems(data);
      })
      .catch(error => {
        console.error('Error fetching menu items:', error);
        setError('Failed to fetch menu items. Is the backend server running?');
      });
  }, []);

  useEffect(() => {
    setOrderVisible(order.length > 0);
  }, [order]);

  useEffect(() => {
    const calculatedTotal = order.reduce(
      (sum, item) => sum + parseFloat(item.price || 0) * (item.quantity || 1),
      0
    );
    setTotal(calculatedTotal);
  }, [order]);

  useEffect(() => {
    const entreeLimit = comboType === 'Bowl' ? 1 : comboType === 'Plate' ? 2 : comboType === 'Bigger Plate' ? 3 : 0;
    const isComboReady = selectedSide && selectedEntrees.reduce((sum, e) => sum + e.quantity, 0) === entreeLimit;
    setIsComboComplete(isComboReady);
  }, [comboType, selectedSide, selectedEntrees]);

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setComboType(null);
    setSelectedSingleItem(null);
  };

  const handleComboSelectionMode = () => {
    setSelectedCategory('Combo');
    setComboType(null);
    setSelectedSide(null);
    setSelectedEntrees([]);
  };

  const selectSide = (side) => {
    setSelectedSide({ ...side, quantity: 1 });
  };

  const addOrRemoveEntree = (entreeName, action) => {
    const entreeLimit = comboType === 'Bowl' ? 1 : comboType === 'Plate' ? 2 : comboType === 'Bigger Plate' ? 3 : 0;
    setSelectedEntrees((prevEntrees) => {
      const existingEntree = prevEntrees.find(e => e.name === entreeName);
      const currentTotal = prevEntrees.reduce((sum, e) => sum + e.quantity, 0);

      if (existingEntree) {
        const updatedEntrees = prevEntrees.map(e => {
          if (e.name === entreeName) {
            const newQuantity = action === "increase" && currentTotal < entreeLimit
              ? e.quantity + 1
              : action === "decrease" && e.quantity > 1
              ? e.quantity - 1
              : e.quantity;
            return { ...e, quantity: newQuantity };
          }
          return e;
        });
        return updatedEntrees;
      } else if (action === "increase" && currentTotal < entreeLimit) {
        const item = menuItems.find(e => e.name === entreeName);
        return [...prevEntrees, { ...item, quantity: 1 }];
      }
      return prevEntrees;
    });
  };

  const addComboToOrder = () => {
    if (isComboComplete) {
      const comboPrice = parseFloat(selectedSide.price) + selectedEntrees.reduce((sum, e) => sum + parseFloat(e.price) * e.quantity, 0);
      const combo = {
        type: comboType,
        side: selectedSide,
        entrees: selectedEntrees,
        price: comboPrice.toFixed(2),
        quantity: 1
      };
      addItemToOrder(combo);
      setComboType(null);
      setSelectedSide(null);
      setSelectedEntrees([]);
      setSelectedCategory(null);
    }
  };

  const selectSingleItem = (item) => {
    setSelectedSingleItem({ ...item, quantity: 1 });
  };

  const addSingleItemToOrder = () => {
    if (selectedSingleItem) {
      const existingItemIndex = order.findIndex(item => item.name === selectedSingleItem.name && !item.type);
      if (existingItemIndex >= 0) {
        updateItemQuantity(existingItemIndex, selectedSingleItem.quantity);
      } else {
        addItemToOrder(selectedSingleItem);
      }
      setSelectedSingleItem(null);
    }
  };

  const updateQuantity = (index, change) => {
    const item = order[index];
    const newQuantity = item.quantity + change;
    if (newQuantity > 0) {
      updateItemQuantity(index, change);
    } else {
      removeItemFromOrder(index);
    }
  };

  const handleCheckout = async () => {
    if (order.length === 0) {
      setError('Cannot checkout with an empty order.');
      return;
    }

    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: order,
          total_amount: total,
          specialRequests: specialRequests
        }),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setShowConfirmation(true);
        clearOrder();
        setSpecialRequests('');
        setTimeout(() => {
          setShowConfirmation(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setError('Failed to complete checkout. Please try again.');
    }
  };

  const filteredMenuItems = menuItems.filter(
    item => item.category.toLowerCase() === selectedCategory?.toLowerCase()
  );

  return (
    <div className="cashier-view">
      <div className="cashier-nav-buttons">
        <h1>Cashier</h1>
        <button onClick={handleComboSelectionMode}>Combo</button>
        <button onClick={() => handleCategorySelection('Appetizer')}>Appetizers</button>
        <button onClick={() => handleCategorySelection('Entree')}>Entrees</button>
        <button onClick={() => handleCategorySelection('Side')}>Sides</button>
        <button onClick={() => handleCategorySelection('Drink')}>Drinks</button>
      </div>
  
      <div className="cashier-menu-container">
        {selectedCategory === 'Combo' ? (
          <div className="cashier-combo-selection">
            <h2>Select a Combo</h2>
            <div className="cashier-combo-type-section">
              <h3>Choose a Combo Type</h3>
              <button onClick={() => setComboType("Bowl")}>Bowl</button>
              <button onClick={() => setComboType("Plate")}>Plate</button>
              <button onClick={() => setComboType("Bigger Plate")}>Bigger Plate</button>
            </div>
            {comboType && (
              <div className="cashier-side-selection">
                <h3>Choose a Side</h3>
                <div className="cashier-side-options">
                  {menuItems.filter(item => item.category === 'Side').map((side) => (
                    <button
                      key={side.name}
                      className={selectedSide && selectedSide.name === side.name ? "selected" : ""}
                      onClick={() => selectSide(side)}
                    >
                      <span data-original-text={side.name}>{side.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}

            {comboType && (
              <div className="cashier-entree-selection">
                <h3>Choose Entrees</h3>
                <p>Select {comboType === 'Bowl' ? 1 : comboType === 'Plate' ? 2 : 3} entrée(s).</p>
                <div className="cashier-entree-options">
                  {menuItems.filter(item => item.category === 'Entree').map((entree) => (
                    <button
                      key={entree.name}
                      onClick={() => addOrRemoveEntree(entree.name, "increase")}
                      className={selectedEntrees.find(e => e.name === entree.name) ? "selected" : ""}
                    >
                      <span data-original-text={entree.name}>{entree.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <h2>{selectedCategory ? `${selectedCategory} Menu` : 'Please select a category'}</h2>
            {error ? (
              <div className="cashier-error-message">{error}</div>
            ) : (
              <div className="cashier-menu-items-contianer">
                {filteredMenuItems.map(item => (
                  <button
                    key={item.id}
                    className="cashier-menu-item-container"
                    onClick={() => selectSingleItem(item)}
                  >
                    <span data-original-text={item.name}>{item.name}</span>
                  </button>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <div className={`cashier-order-summary ${orderVisible ? 'visible' : ''}`}>
        <h2>Your Order</h2>
        <div className="cashier-current-combo-summary">
          {comboType && (
            <>
              <strong>Current Combo:</strong> {comboType}<br />
              <strong>Side:</strong> 
              {selectedSide ? (
                <span>
                  {selectedSide.name}
                  <button onClick={() => setSelectedSide(null)} className="cashier-remove-button">Remove</button>
                </span>
              ) : "None"}<br />
              <strong>Entrees:</strong> 
              {selectedEntrees.length > 0 ? (
                selectedEntrees.map((entree, idx) => (
                  <div key={idx} className="cashier-combo-entree">
                    <span>{entree.name} (x{entree.quantity})</span>
                    <div className="cashier-combo-entree-buttons">
                      <button
                        onClick={() => addOrRemoveEntree(entree.name, "decrease")}
                        disabled={entree.quantity === 1}
                      >
                        -
                      </button>
                      <button onClick={() => addOrRemoveEntree(entree.name, "increase")}>
                        +
                      </button>
                    </div>
                    <button
                      onClick={() => setSelectedEntrees(prev => prev.filter(e => e.name !== entree.name))}
                      className="cashier-remove-button"
                    >
                      Remove
                    </button>
                  </div>
                ))
                
              ) : (
                "None"
              )}
              <br />
              <button
                onClick={addComboToOrder}
                disabled={!isComboComplete}
                className="cashier-add-combo-button"
              >
                Add Combo to Order
              </button>
            </>
          )}

          {selectedSingleItem && (
            <div className="cashier-single-item-summary">
              <strong>Selected Item:</strong> {selectedSingleItem.name} (${parseFloat(selectedSingleItem.price).toFixed(2)})
              <button onClick={addSingleItemToOrder} className="cashier-add-single-item-button">Add to Order</button>
            </div>
          )}
        </div>
        
        <ul>
          {order.map((item, index) => (
            <li key={index}>
              {item.type ? (
                <span data-original-text={`${item.type} - $${item.price} - Side: ${item.side ? item.side.name : "None"}, Entrees: ${item.entrees.map(e => `${e.name} (x${e.quantity})`).join(', ')}`}>
                  {`${item.type} - $${item.price} - Side: ${item.side ? item.side.name : "None"}, Entrees: ${item.entrees.map(e => `${e.name} (x${e.quantity})`).join(', ')}`}
                </span>
              ) : (
                <>
                  <span data-original-text={item.name}>{item.name}</span> - ${parseFloat(item.price || 0).toFixed(2)} 
                  <button onClick={() => updateQuantity(index, -1)} disabled={item.quantity === 1}>-</button>
                  {item.quantity}
                  <button onClick={() => updateQuantity(index, 1)}>+</button>
                </>
              )}
              <button onClick={() => removeItemFromOrder(index)} className="cashier-remove-button">Remove</button>
            </li>
          ))}
        </ul>
        <p>Total: ${total.toFixed(2)}</p>
        <div className="special-requests">
          <label htmlFor="special-requests">Special Requests:</label>
          <textarea
            id="special-requests"
            value={specialRequests}
            onChange={(e) => setSpecialRequests(e.target.value)}
            rows="3"
          />
        </div>
        <button onClick={clearOrder} className="cashier-clear-order-button">Clear Order</button>
        <button onClick={handleCheckout} className="cashier-checkout-button" disabled={order.length === 0}>Checkout</button>
      </div>
      {showConfirmation && (
        <div className="cashier-order-confirmation">
          <h2>Order Placed Successfully!</h2>
          <p>Thank you for your order.</p>
          <button onClick={() => setShowConfirmation(false)}>Continue Shopping</button>
        </div>
      )}
    </div>
  );
}

export default CashierView;