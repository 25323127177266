// Import necessary React hooks and routing utilities
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Import component-specific styling
import './LanguageSelector.css';

// Import custom translation hook for language translation
import { useTranslation } from '../hooks/useTranslation';

/**
 * LanguageSelector Component
 * 
 * Provides an interactive dropdown to change the application's language.
 * Uses Google Translate API to dynamically translate page content.
 * Supports multiple languages and preserves original text.
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {Function} props.onLanguageChange - Callback function triggered when language changes
 * @returns {React.ReactElement} Rendered language selector dropdown
 */
function LanguageSelector({ onLanguageChange }) {
  const { translate, loading } = useTranslation(process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY);
  const location = useLocation();
  const observerRef = useRef(null);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'zh', name: '中文' },
    { code: 'vi', name: 'Tiếng Việt' },
    { code: 'tl', name: 'Tagalog' },
    { code: 'fr', name: 'Français' }
  ];

  /**
   * Translates a single element's text content
   * 
   * @async
   * @param {HTMLElement} element - The element to translate
   * @param {string} langCode - Target language code
   */
  const translateElement = async (element, langCode) => {
    // Skip translation for elements in the language selector
    if (element.closest('.language-selector')) return;
    
    // Skip elements that contain other elements (to avoid duplicate translations)
    // Exception for specific containers that need translation
    if (element.children.length > 0 && 
        !element.classList?.contains('info-modal') && 
        !element.classList?.contains('popular-item') && 
        !element.classList?.contains('category-card')) return;
    
    // Get the text to translate
    let originalText = element.getAttribute('data-original-text');
    if (!originalText) {
      // For database text, check if we have a data attribute
      originalText = element.getAttribute('data-db-text') || element.textContent.trim();
      if (element.hasAttribute('data-db-text')) {
        element.setAttribute('data-original-text', originalText);
      }
    }
    if (!originalText) return;

    // Skip if already translated to this language
    const currentLang = element.getAttribute('data-translated-lang');
    if (currentLang === langCode) return;

    // Skip translation if the element is being processed
    if (element.getAttribute('data-translating') === 'true') return;

    if (langCode === 'en') {
      element.textContent = originalText;
      element.removeAttribute('data-translated-lang');
    } else {
      try {
        // Mark element as being processed
        element.setAttribute('data-translating', 'true');
        const translatedText = await translate(originalText, langCode);
        if (!element.hasAttribute('data-original-text')) {
          element.setAttribute('data-original-text', originalText);
        }
        element.textContent = translatedText;
        element.setAttribute('data-translated-lang', langCode);
      } catch (error) {
        console.error('Translation failed for element:', error);
      } finally {
        // Remove processing mark
        element.removeAttribute('data-translating');
      }
    }
  };

  /**
   * Sets up mutation observer to watch for dynamically added content
   * 
   * @param {string} langCode - Current language code
   */
  const setupObserver = (langCode) => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(async (mutation) => {
        // Handle attribute changes (for modal content updates)
        if (mutation.type === 'attributes' && mutation.target.nodeType === Node.ELEMENT_NODE) {
          const element = mutation.target;
          if (element.textContent.trim() && !element.closest('.language-selector')) {
            await translateElement(element, langCode);
          }
        }
        
        // Handle added nodes
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(async (node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              // Handle modal content specifically
              if (node.classList?.contains('info-modal') || node.classList?.contains('modal')) {
                const modalElements = node.querySelectorAll('*');
                for (const element of modalElements) {
                  if (element.textContent.trim() && !element.children.length) {
                    await translateElement(element, langCode);
                  }
                }
              }
              
              // Handle all other text content
              const elements = node.querySelectorAll('h1, h2, h3, h4, h5, h6, p, span, button, a, label, option, div[class*="desc"], div[class*="text"], strong');
              for (const element of elements) {
                if (element.textContent.trim() && !element.children.length) {
                  await translateElement(element, langCode);
                }
              }
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });

    observerRef.current = observer;
  };

  /**
   * Handles language selection and translation of page content
   * 
   * @async
   * @param {string} langCode - The language code to translate to
   */
  const handleLanguageSelect = async (langCode) => {
    if (loading) return;
    
    try {
      localStorage.setItem('selectedLanguage', langCode);

      // Set up observer for dynamic content
      setupObserver(langCode);

      // Translate all existing content
      const elements = document.querySelectorAll('h1, h2, h3, h4, h5, h6, p, span, button, a, label, option, div[class*="desc"], div[class*="text"]');
      
      for (const element of elements) {
        await translateElement(element, langCode);
      }

      onLanguageChange(langCode);
    } catch (error) {
      console.error('Translation failed:', error);
    }
  };

  // Initial setup on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage && savedLanguage !== 'en') {
      handleLanguageSelect(savedLanguage);
    }
    
    // Cleanup observer on unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  // Handle route changes
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage && savedLanguage !== 'en') {
      setTimeout(() => {
        handleLanguageSelect(savedLanguage);
      }, 100);
    }
  }, [location.pathname]);

  return (
    <div className="language-selector">
      <select 
        onChange={(e) => handleLanguageSelect(e.target.value)} 
        disabled={loading}
        value={localStorage.getItem('selectedLanguage') || 'en'}
        aria-label="Select language"
      >
        {languages.map(lang => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>

      {loading && <span className="loading-indicator" role="status">Translating...</span>}
    </div>
  );
}

export default LanguageSelector; 