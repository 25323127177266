// Import core React library for component creation
import React from 'react';

// Import component-specific styling
import './Home.css';

// Import routing utilities for navigation
import { Link } from 'react-router-dom';

/**
 * Home Component
 * 
 * Landing page for the Panda Express Point of Sale (POS) system.
 * Provides authentication and navigation to different application sections.
 * Serves as the central hub for accessing various app functionalities.
 * 
 * @component
 * @returns {React.ReactElement} Rendered home page with navigation links
 */
function Home() {
  return (
    /**
     * Main container for the home page
     * Structured to provide a clean, intuitive user interface
     */
    <div className="home">
      {/* Header section showcasing app branding */}
      <div className="header">
        {/* 
         * App title with internationalization support
         * data-translate attribute enables dynamic language translation
         */}
        <h1 data-translate="true">Panda Express POS</h1>
        
        {/* 
         * App logo with responsive scaling
         * Uses SVG for crisp rendering across different screen sizes
         * 
         * @property {string} src - Path to logo asset
         * @property {string} alt - Accessibility text description
         * @property {string} className - CSS class for styling
         */}
        <img 
          src="/assets/Panda_Express.svg"
          alt="Logo"
          className="logo-image"
        />
      </div>
      

      {/* 
       * Navigation section with links to different app views
       * Provides quick access to key application functionalities
       */}
      <div className="home-links">
        {/* 
         * Link to customer order placement view
         * Allows customers to browse menu and create orders
         * 
         * @property {string} to - Destination route
         * @property {string} className - Styling class
         */}
        <Link 
          to="/customer"
          className="home-link"
        >
          Order Now
        </Link>
        
        {/* 
         * Link to cashier view
         * Enables order processing and payment handling
         * 
         * @property {string} to - Destination route
         * @property {string} className - Styling class
         */}
        <Link 
          to="/cashier" 
          className="home-link"
        >
          Cashier
        </Link>
        
        {/* 
         * Link to manager view
         * Provides administrative and management functionalities
         * 
         * @property {string} to - Destination route
         * @property {string} className - Styling class
         */}
        <Link 
          to="/manager" 
          className="home-link"
        >
          Manager
        </Link>
      </div>
    </div>
  );
}

// Export Home component as default
// Makes the component available for use in routing and other parts of the application
export default Home;
